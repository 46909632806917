import './Steps.scss';
import Step from './Step';

const Steps = ({ howMany = 1, currentStep }) => {
  const steps = new Array(howMany).fill('');

  return (
    <div className="steps">
      {howMany === 1 ? (
        <Step num={1} howMany={howMany} currentStep={currentStep} />
      ) : (
        steps.map((step, i) => (
          <Step
            key={i}
            num={i + 1}
            howMany={howMany}
            currentStep={currentStep}
          />
        ))
      )}
    </div>
  );
};

export default Steps;
