import './Home.scss';
import { useState } from 'react';
// import { BalanceIcon } from '../../assets/icons';
import { Btn } from '../../components/UI';
import { useHistory } from 'react-router-dom';
import { Carousel } from './components';
import { ArrowIcon } from '../../assets/icons';

const Home = () => {
  const [currentSquare, setCurrentSquare] = useState(1);
  const history = useHistory();
  const squares = new Array(7).fill('');

  const startQuestionary = () => {
    history.push('/questionary');
  };

  return (
    <div className="home view">
      <div className="home__top">
        <div className="home__top--left">
          <div className="home__top--left--desc">
            <p>
              Migration Investments is pleased to announce the launch of our
              new, UK patent pending App.
            </p>
            <p>
              Our team of experts with over 20 years of industry experience have
              carefully designed this App to provide you with a first-class
              consultation, available right at your fingertips.
            </p>
            <p>
              We know that your time is valuable, which is why our unique App
              will save you time and money by eliminating the need to endure
              long and costly consultations to learn about which programs are
              best suited to your specific requirements.
            </p>
            <p>
              To get started, simply click the button below to launch our App,
              answer the questions given, then sit back and let our App analyze
              your answers then recommend the best program options for you and
              your family.
            </p>
          </div>

          <div className="home__top--left--main">
            <Btn text="Launch App" clicked={startQuestionary} cta={true} />
            <span className="patent">UK PATENT PENDING 2107002.4</span>
          </div>
        </div>
        <div className="home__top--right">
          <Carousel currentSquare={currentSquare} />
        </div>
      </div>
      <div className="home__bottom">
        <div className="home__bottom--squares">
          {squares &&
            squares.map((square, i) => (
              <div
                key={i}
                className={currentSquare === i + 1 ? 'square active' : 'square'}
                onClick={() => setCurrentSquare(i + 1)}
              ></div>
            ))}
        </div>
        <div className="home__bottom--arrows">
          <ArrowIcon
            className="rotate180"
            onClick={() =>
              setCurrentSquare((prevSquare) => {
                if (prevSquare === 1) {
                  return squares.length;
                } else {
                  return prevSquare - 1;
                }
              })
            }
          />
          <div className="line"></div>
          <ArrowIcon
            onClick={() =>
              setCurrentSquare((prevSquare) => {
                if (prevSquare >= squares.length) {
                  return 1;
                } else {
                  return prevSquare + 1;
                }
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
