import './Questionary.scss';
import { useState } from 'react';
import { WorldMap } from '../../assets/images';
import { QuestionaryForm, RecaptchaForm } from './components';
import { Steps } from '../../components';

const Questionary = () => {
  const [howMany] = useState(6);
  const [currentStep, setCurrentStep] = useState(1);
  //const [contactStep, setContactStep] = useState(1);
  const [isRecaptchaSow, setIsRecaptchaSow] = useState(true);
  const [isContactInfoSow, setIsContactInfoSow] = useState(false);

  const style = {
    backgroundImage: `url(${WorldMap})`,
  };

  const toggleContactInfo = (v) => {
    if (v) {
      setIsContactInfoSow(true);
    } else {
      setIsContactInfoSow(false);
    }
  };

  return (
    <div className="questionary view">
      <div className="questionary__top">
        {!isRecaptchaSow ? (
          isContactInfoSow ? (
            <p className="questionary__msg">
              Please fill out the contact form below so that we can send you the
              results of your consultation and highlight the next steps for you
              to take in your process towards acquiring your desired residency
              or citizenship.
            </p>
          ) : (
            <Steps howMany={howMany - 1} currentStep={currentStep} />
          )
        ) : (
          <p className="questionary__msg">
            Please fill-up the form to proceed with the questionnaire. We will
            get in contact with you shortly after you complete the
            questionnaire.
          </p>
        )}
      </div>
      <div className="questionary__bottom" style={style}>
        {isRecaptchaSow ? (
          <RecaptchaForm startQuestionary={() => setIsRecaptchaSow(false)} />
        ) : (
          <QuestionaryForm
            howMany={howMany}
            currentStep={currentStep}
            isContactInfoSow={isContactInfoSow}
            toggleContactInfo={toggleContactInfo}
            prevQuestion={() => setCurrentStep(currentStep - 1)}
            nextQuestion={() => setCurrentStep(currentStep + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default Questionary;
