const initState = {
  isMenuOpen: false,
};

export const navigationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        isMenuOpen: !state.isMenuOpen,
      };
    default:
      return state;
  }
};
