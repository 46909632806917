import firebase from 'firebase/app';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyBgUABv7RbQmmX0yfIk-iruXRxIAvwM_i4',
  authDomain: 'migration-investments-app.firebaseapp.com',
  projectId: 'migration-investments-app',
  storageBucket: 'migration-investments-app.appspot.com',
  messagingSenderId: '532556013711',
  appId: '1:532556013711:web:788149c41d78934cf150fa',
  measurementId: 'G-PM5YNTQZ22',
};

firebase.initializeApp(firebaseConfig);

const functions = firebase.functions();

export { functions };
