import './Btn.scss';

const Btn = ({
  text,
  text2,
  clicked,
  cta,
  prevIcon,
  nextIcon,
  btnType,
  isDisabled,
}) => {
  return (
    <button
      disabled={isDisabled}
      type={btnType}
      className={cta ? 'btn cta' : 'btn'}
      onClick={clicked}
    >
      {prevIcon && <span>Prev</span>}
      <span>{text} </span>
      <span>{text2}</span>
      {nextIcon && <span>Next</span>}
    </button>
  );
};

export default Btn;
