const Step = ({ num, howMany, currentStep }) => {
  return (
    <>
      <div className={currentStep >= num ? 'step active' : 'step'}>
        <span>{num}</span>
      </div>
      {num !== howMany && (
        <div
          className={currentStep >= num + 1 ? 'step-line active' : 'step-line'}
        ></div>
      )}
    </>
  );
};

export default Step;
