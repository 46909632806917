import './MobileNavbar.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../store/actions/navigationActions';

const MobileNavbar = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.nav.isMenuOpen);

  return (
    <nav className={isMenuOpen ? 'navbar__mobile' : 'navbar__mobile close'}>
      <ul className="navbar__mobile--menu">
        <NavLink exact to="/" onClick={() => dispatch(toggleMenu())}>
          Home
        </NavLink>
        <NavLink to="/questionary" onClick={() => dispatch(toggleMenu())}>
          Questionnaire
        </NavLink>
        <li
          className="navbar__mobile--link"
          onClick={() => dispatch(toggleMenu())}
        >
          <a
            href="https://migration.investments/"
            target="_blank"
            rel="noreferrer"
          >
            Programs
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavbar;
