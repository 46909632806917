import React from 'react';
import {
  CanadaFalg,
  DominicaFalg,
  SpainFalg,
  UKFalg,
  GrenadaFalg,
  GreeceFalg,
  KittsAndNavisFalg,
  LatviaFalg,
  MontenegroFalg,
  MaltaFalg,
  PortugalFalg,
  TurkeyFalg,
  USFalg,
  World,
} from '../../../../assets/icons/flags';
import {
  CanadaImgWebp,
  CanadaImgPng,
  DominicaImgWebp,
  DominicaImgPng,
  GreeceImgWebp,
  GreeceImgPng,
  GrenadaImgWebp,
  GrenadaImgPng,
  LatviaImgWebp,
  LatviaImgPng,
  MaltaImgWebp,
  MaltaImgPng,
  MontenegroImgWebp,
  MontenegroImgPng,
  PortugalImgWebp,
  PortugalImgPng,
  SpainImgWebp,
  SpainImgPng,
  StKittsAndNevisImgWebp,
  StKittsAndNevisImgPng,
  TurkeyImgWebp,
  TurkeyImgPng,
  UkImgWebp,
  UkImgPng,
  UsImgWebp,
  UsImgPng,
  WorldImgWebp,
  WorldImgPng,
} from '../../../../assets/images';

export const countries = [
  {
    id: 1,
    name: 'United Kingdom',
    description:
      'The UK is one of the most attractive places in the world for business and for private residence. It is known for having a stable democratic government, a respected system of law, an international business environment, a multi-cultural capital and world-renowned schools and universities...',
    flag: <UKFalg />,
    imgSrcWebp: UkImgWebp,
    imgSrcPng: UkImgPng,
    href: 'https://migration.investments/programs/uk',
  },
  {
    id: 2,
    name: 'United States',
    description:
      'The fastest and most effective ways to get a Green Card is the EB5 visa. It has helped hundreds of families, business owners and entrepreneurs relocate to America. There are around 500 EB-5 visas available for each country each year...',
    flag: <USFalg />,
    imgSrcWebp: UsImgWebp,
    imgSrcPng: UsImgPng,
    href: 'https://migration.investments/programs/us',
  },
  {
    id: 3,
    name: 'Portugal',
    description:
      'The Portuguese government has actively promoted and supported an easy and beneficial process of gaining residency through investment since 2012. Over 6,500 foreign investors have poured into Portugal through the program, adding almost 5 billion euros to the economy...',
    flag: <PortugalFalg />,
    imgSrcWebp: PortugalImgWebp,
    imgSrcPng: PortugalImgPng,
    href: 'https://migration.investments/programs/portugal',
  },
  {
    id: 4,
    name: 'Spain',
    description:
      'Spain’s Golden Visa is one of Europe’s most successful citizenship by investment programs.Spain’s appeal for foreign investors lies not only in its domestic market, with 46.6 million potential consumers and around 80 million tourists who visit the country each year...',
    flag: <SpainFalg />,
    imgSrcWebp: SpainImgWebp,
    imgSrcPng: SpainImgPng,
    href: 'https://migration.investments/programs/spain',
  },
  {
    id: 5,
    name: 'Latvia',
    description:
      'Latvia is situated in Northern Europe on the Baltic Sea and is known for its rich heritage, incredible local cuisine, and stunning mix of art nouveau and medieval architecture. Latvia attracts visitors from all over the world...',
    flag: <LatviaFalg />,
    imgSrcWebp: LatviaImgWebp,
    imgSrcPng: LatviaImgPng,
    href: 'https://migration.investments/programs/latvia',
  },
  {
    id: 6,
    name: 'St Kitts and Nevis',
    description:
      'St. Kitts and Nevis is a dual-island Caribbean destination that offers pristine beaches and tropical landscapes as well as cultural diversity influenced by African, British, Carib, and French cultures...',
    flag: <KittsAndNavisFalg />,
    imgSrcWebp: StKittsAndNevisImgWebp,
    imgSrcPng: StKittsAndNevisImgPng,
    href: 'https://migration.investments/programs/st_kitts_and_nevis',
  },
  {
    id: 7,
    name: 'Malta',
    description:
      'Malta’s government published the regulations for its new expedited route to citizenship through investment. The previous citizenship by investment program (MIIP) was officially closed after it reached the cap of 1800 applications...',
    flag: <MaltaFalg />,
    imgSrcWebp: MaltaImgWebp,
    imgSrcPng: MaltaImgPng,
    href: 'https://migration.investments/programs/malta',
  },
  {
    id: 8,
    name: 'Turkey',
    description:
      'Invest $250,000 in real estate and get your Turkish Passport in less than 2 months. We are one of the oldest, most established companies, processing residency applications for more than 20 years.',
    flag: <TurkeyFalg />,
    imgSrcWebp: TurkeyImgWebp,
    imgSrcPng: TurkeyImgPng,
    href: 'https://cipturkey.net/turkish-citizenship-by-investment',
  },
  {
    id: 9,
    name: 'Canada',
    description:
      'Canada holds the place as one of the most desirable places in the world to live. It is one of the most multicultural places in the world, making it a preferred destination for immigrants...',
    flag: <CanadaFalg />,
    imgSrcWebp: CanadaImgWebp,
    imgSrcPng: CanadaImgPng,
    href: 'https://migration.investments/programs/canada',
  },
  {
    id: 10,
    name: 'Dominica',
    description:
      'Dominica has become one of the most affordable and flexible second passport programmes available. Established in 1993, the Dominica Economic Citizenship Program legally offers individuals and families worldwide an irrevocable second citizenship and passport in only four to six months with no visit to the country required...',
    flag: <DominicaFalg />,
    imgSrcWebp: DominicaImgWebp,
    imgSrcPng: DominicaImgPng,
    href: 'https://migration.investments/programs/dominica',
  },
  {
    id: 11,
    name: 'Greece',
    description:
      'The Golden Visa for Greece is one of the most popular investment visa programs in the EU. It is a residence-by-investment visa, issued to non-EU citizens who make a significant contribution to the Greek economy...',
    flag: <GreeceFalg />,
    imgSrcWebp: GreeceImgWebp,
    imgSrcPng: GreeceImgPng,
    href: 'https://migration.investments/programs/greece',
  },
  {
    id: 12,
    name: 'Grenada',
    description:
      'The citizenship by investment programme for Grenada is one of the newest programmes available to investors. The Grenada investment visa programme provides second citizenship in Grenada and a passport to investors offering visa-free travel to over 153 countries including the UK, EU Schengen countries and China...',
    flag: <GrenadaFalg />,
    imgSrcWebp: GrenadaImgWebp,
    imgSrcPng: GrenadaImgPng,
    href: 'https://migration.investments/programs/grenada',
  },
  {
    id: 13,
    name: 'Montenegro',
    description:
      'Montenegro has one of the fastest-growing economies on the Balkan Peninsula. The country is quickly becoming a key destination for investors looking for mobility, security, and better quality of life. In a strategic geographical location in Europe with direct access to the Adriatic Sea...',
    flag: <MontenegroFalg />,
    imgSrcWebp: MontenegroImgWebp,
    imgSrcPng: MontenegroImgPng,
    href: 'https://migration.investments/programs/montenegro',
  },
  {
    id: 14,
    name: 'World',
    description: 'More countries comming soon...',
    flag: <World />,
    imgSrcWebp: WorldImgWebp,
    imgSrcPng: WorldImgPng,
    href: 'https://migration.investments/',
  },
];
