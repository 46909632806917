import { combineReducers } from 'redux';
import { contactInfoReducer } from './contactInfoReducer';
import { navigationReducer } from './navigationReducer';

const rootReducer = combineReducers({
  contactInfo: contactInfoReducer,
  nav: navigationReducer,
});

export default rootReducer;
