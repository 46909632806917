import '../questionaryForm/QuestionaryForm.scss';
import { useEffect, useState } from 'react';
import { Btn } from '../../../../components/UI';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser } from '../../../../store/actions/contactInfoActions';
import { countries } from '../../../../shared/countries';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const ContactInfo = ({ saveContactInfo }) => {
  const dispatch = useDispatch();
  const { isEU, isEEA } = useSelector((state) => state.contactInfo);
  const [isContactInfoSaved, setIsContactInfoSaved] = useState(false);
  const [isContactValid, setIsContactValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [user, setUser] = useState({
    name: '',
    email: '',
    currentResidency: 'Other',
    currentCitizenship: 'Other',
  });

  const save = (e) => {
    e.preventDefault();
    //console.log({ user });
    dispatch(
      saveUser({
        name: user.name,
        email: user.email,
        phone,
        currentResidency: user.currentResidency,
        currentCitizenship: user.currentCitizenship,
        isEU,
        isEEA,
      })
    );

    saveContactInfo();
    setIsContactInfoSaved(true);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  const checkValidation = () => {
    const { name, email, currentResidency, currentCitizenship } = user;
    //let isPhoneValid = isValidPhoneNumber(String(phone));

    if (
      name.length >= 3 &&
      email.length >= 5 &&
      phone?.length >= 5 &&
      currentResidency &&
      currentCitizenship
    ) {
      setErrorMsg(null);
      setIsContactValid(true);
    } else {
      setIsContactValid(false);
    }
  };

  useEffect(() => {
    checkValidation();
    //let isPhoneValid = isValidPhoneNumber(String(phone));
    let isPhoneValid = phone?.length >= 5;

    if (isPhoneValid) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
    // eslint-disable-next-line
  }, [user, phone]);

  useEffect(() => {
    const phoneII = document.querySelector('.PhoneInputInput');
    let isPhoneValid = phone?.length >= 5;
    if (!isPhoneValid) {
      phoneII.classList.add('error');
    } else {
      phoneII.classList.remove('error');
    }
    // eslint-disable-next-line
  }, [phone]);

  return (
    <>
      <div className="input-field contact-info">
        <input
          type="text"
          placeholder="Name"
          id="name"
          name="name"
          autoComplete="off"
          className={user.name.length < 3 ? 'error' : ''}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value.length < 3) {
              setErrorMsg('Name must be at least 3 characters long');
            } else {
              setErrorMsg(null);
            }
          }}
          value={user.name}
          required
        />
        <label htmlFor="name">Name</label>
      </div>
      <div className="input-field contact-info">
        <input
          type="email"
          placeholder="Email"
          id="email"
          name="email"
          autoComplete="off"
          className={user.email.length >= 5 ? '' : 'error'}
          onChange={(e) => {
            handleChange(e);
            if (user.email.length < 4) {
              setErrorMsg('Please enter a valid email');
            } else {
              setErrorMsg(null);
            }
          }}
          value={user.email}
          required
        />
        <label htmlFor="email">Email</label>
      </div>
      <div
        className="input-field contact-info"
        onKeyDown={() => {
          if (!isPhoneValid) {
            setErrorMsg('Please enter a valid phone number');
          } else {
            setErrorMsg(null);
          }
        }}
      >
        <PhoneInput
          placeholder="Enter phone number"
          value={phone}
          onChange={setPhone}
          required
        />
      </div>
      <p style={{ marginBottom: '12px', opacity: '0.8' }}>
        Please provide info about your current circumstances for us to find you
        the best fit.
      </p>
      <div className="input-field">
        <label htmlFor="currentResidency">Your Residency</label>
        <select
          name="currentResidency"
          id="currentResidency"
          onChange={handleChange}
          value={user.currentResidency}
          required
        >
          {countries.map((country, i) => (
            <option key={i} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>
      <div className="input-field" style={{ marginBottom: '16px' }}>
        <label htmlFor="currentCitizenship">Your Citizenship</label>
        <select
          name="currentCitizenship"
          id="currentCitizenship"
          onChange={handleChange}
          value={user.currentCitizenship}
          required
        >
          {countries.map((country, i) => (
            <option key={i} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>{' '}
      <p style={{ marginBottom: '24px', opacity: '0.8' }}>
        Please click "Save" before submitting
      </p>
      <div className="input-field btns" style={{ width: '50%' }}>
        <Btn
          btnType="button"
          text={isContactInfoSaved ? 'Saved' : 'Save'}
          isDisabled={!isContactValid || isContactInfoSaved}
          clicked={save}
        />
      </div>
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
    </>
  );
};

export default ContactInfo;
