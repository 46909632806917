import { useEffect, useState } from 'react';
import './Carousel.scss';
import { countries } from './countries';

const Carousel = ({ currentSquare }) => {
  //const [percentage, setPercentage] = useState(325.6);
  const [percentage, setPercentage] = useState(217.12);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 1400 && windowWidth >= 1201) {
      setPercentage(217.49);
    }
    if (windowWidth < 1201 && windowWidth >= 1025) {
      setPercentage(218.64);
    }
    if (windowWidth < 1025 && windowWidth >= 901) {
      setPercentage(220.19);
    }
    if (windowWidth < 901 && windowWidth >= 601) {
      setPercentage(219.23);
    }
    if (windowWidth < 601 && windowWidth >= 481) {
      setPercentage(220);
    }
    if (windowWidth < 601 && windowWidth >= 481) {
      setPercentage(219.74);
    }
    //console.log(windowWidth);
  }, []);

  return (
    <div className="carousel">
      {countries.map((country) => (
        <div
          key={country.id}
          className="carousel__country"
          style={{
            transform: `translateX(${(currentSquare - 1) * -percentage}%)`,
          }}
        >
          <picture>
            <source srcSet={country.imgSrcWebp} type="image/webp" />
            <source srcSet={country.imgSrcPng} type="image/png" />
            <img src={country.imgSrcPng} alt={country.name} />
          </picture>
          <div className="carousel__country--glass">
            <a href={country.href} target="_blank" rel="noreferrer">
              <h2>
                <span>{country.flag}</span>
                {country.name}
              </h2>
            </a>
            <p>{country.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
