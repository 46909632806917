import './Navbar.scss';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../store/actions/navigationActions';
import { MiLogi } from '../../assets/icons';

const Navbar = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.nav.isMenuOpen);

  return (
    <nav className="navbar">
      <div className="navbar__title">
        <Link to="/">
          <h1 className="navbar__title--all">
            <MiLogi />
            migration<span style={{ color: '#8BD8BD' }}>.</span>investments
          </h1>
          <h1 className="navbar__title--mobile">
            <MiLogi />
          </h1>
        </Link>
      </div>
      <div className="navbar__links">
        <ul className="navbar__menu">
          <NavLink exact to="/">
            Home
          </NavLink>
          <NavLink to="/questionary">Questionnaire</NavLink>
          <li className="navbar__link">
            <a
              href="https://migration.investments/"
              target="_blank"
              rel="noreferrer"
            >
              Programs
            </a>
          </li>
        </ul>
        <div id="google_translate_element" onClick={() => false}></div>
        <div
          className={`navbar__btn ${isMenuOpen ? 'close' : ''}`}
          onClick={() => dispatch(toggleMenu())}
        >
          <div className="bar-1"></div>
          <div className="bar-2"></div>
          <div className="bar-3"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
