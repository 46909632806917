import './App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Navbar } from './components';
import { Home, Questionary } from './views';
// import { ComingSoon } from './views';
import { Backdrop } from './components/UI';
import MobileNavbar from './components/navbar/MobileNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from './store/actions/navigationActions';

function App() {
  const isMenuOpen = useSelector((state) => state.nav.isMenuOpen);
  const dispatch = useDispatch();

  return (
    <BrowserRouter>
      <div className="app">
        {isMenuOpen && <Backdrop clicked={() => dispatch(toggleMenu())} />}
        <Navbar />
        <MobileNavbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/questionary" component={Questionary} />
        </Switch>
      </div>
    </BrowserRouter>
    //<ComingSoon />
  );
}

export default App;
