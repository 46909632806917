import { useEffect, useState } from 'react';
import { Btn } from '../../../../components/UI';
import Loader from '../../../../components/UI/loader/Loader';
import './QuestionaryForm.scss';
import Checkbox from './Checkbox';
import { functions } from '../../../../config/fbConfig';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContactInfo from '../contactInfo/ContactInfo';

const QuestionaryForm = ({
  howMany,
  currentStep,
  isContactInfoSow,
  toggleContactInfo,
  prevQuestion,
  nextQuestion,
}) => {
  const history = useHistory();
  const [title, setTitle] = useState('Questionnaire');
  const user = useSelector((state) => state.contactInfo);
  const [isEnd, setIsEnd] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState('');
  const [date, setDate] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [recommendation, setRecommendation] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isInfoCollected, setIsInfoCollected] = useState(false);
  const [familyMembers, setFamilyMembers] = useState({
    Single: true,
    Spouse: false,
    Parents: false,
    Grandparents: false,
    Siblings: false,
    Children: [
      { _0to18: false },
      { _18to21: false },
      { _22to24: false },
      { _25to26: false },
      { _27toUp: false },
    ],
  });

  useEffect(() => {
    const d = new Date();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const day = d.getDate();
    setDate(
      `${months[d.getMonth()]}/${
        day >= 10 ? day : '0' + day
      }/${d.getFullYear()}`
    );
    //console.log(date);
    // eslint-disable-next-line
  }, []);

  const getFirstQuestion = () => {
    const firstQuestion = functions.httpsCallable('firstQuestion');

    firstQuestion()
      .then((result) => {
        //console.log(result);
        setQuestions(result.data);
        setAnswer(result.data[0].options[0].value);
        setCountries(result.data[0].options[0].countries);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSecondQuestion = () => {
    const secondQuestion = functions.httpsCallable('secondQuestion');

    secondQuestion({
      ans: answer.length > 2 ? answer.slice(0, -2) : answer,
      countries: [countries],
    })
      .then((result) => {
        //console.log(result);
        setQuestions(result.data);
        setAnswer(result.data[0].options[0].value);
        setCountries(result.data[0].options[0].countries);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getThirdQuestion = () => {
    const thirdQuestion = functions.httpsCallable('thirdQuestion');

    thirdQuestion({
      ans: answer.length > 3 ? answer.slice(0, -2) : answer,
      countries: [countries],
    })
      .then((result) => {
        //console.log(result);
        setQuestions(result.data);
        setAnswer(result.data[0].options[0].value);
        setCountries(result.data[0].options[0].countries);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFourthQuestion = () => {
    const fourthQuestion = functions.httpsCallable('fourthQuestion');

    fourthQuestion({ ans: answer.length > 4 ? answer.slice(0, -2) : answer })
      .then((result) => {
        //console.log(result);
        setQuestions(result.data);
      })
      .then(() => {
        if (answer.length === 5) {
          setAnswer(answer.slice(0, -2) + 'A');
          cleanFamiliMember();
        }
      })
      .then(() => {
        if (answer.length < 4) {
          setAnswer(answer + 'A');
          cleanFamiliMember();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFifthQuestion = () => {
    const fifthQuestion = functions.httpsCallable('fifthQuestion');

    fifthQuestion({
      ans: answer,
      countries,
      familyMembers,
    })
      .then((result) => {
        //console.log(result);
        setQuestions(result.data);
        setAnswer(result.data[0].options[0]?.value);
        setCountries(result.data[0].options[0].countries);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendRecommendation = async (formatedCountries, name) => {
    const countriesParam = formatedCountries.join('-');
    const url =
      'https://us-central1-migration-investments-app.cloudfunctions.net/sendEmail';
    //console.log(countrieParam);
    const res = await fetch(
      `${url}?dest=${user.email}&countries=${countriesParam}&name=${name}&ans=${answer}`
    );
    //const data = await res.json();
    return res;
  };

  const addToSpreadsheet = (countriesForSpreadsheet) => {
    const addToSheet = functions.httpsCallable('addToSheet');
    const family = `${familyMembers.Single ? 'Single, ' : ''}${
      familyMembers.Spouse ? 'Spouse, ' : ''
    }${familyMembers.Parents ? 'Parents, ' : ''}${
      familyMembers.Grandparents ? 'Grandparents, ' : ''
    }${familyMembers.Siblings ? 'Siblings, ' : ''}${
      familyMembers.Children[0]['_0to18'] ? 'Children (0-18), ' : ''
    }${familyMembers.Children[1]['_18to21'] ? 'Children (18-21), ' : ''}${
      familyMembers.Children[2]['_22to24'] ? 'Children (21-24), ' : ''
    }${familyMembers.Children[3]['_25to26'] ? 'Children (25-26), ' : ''}${
      familyMembers.Children[4]['_27toUp'] ? 'Children (27-Up), ' : ''
    }`;

    addToSheet({
      client: {
        ...user,
        recommendedCountries: countriesForSpreadsheet,
        familyMembers: family.slice(0, -2),
        ans: answer,
        date,
      },
    })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkIsValid = () => {
    const invalid = questions[0].invalid;

    if (
      (invalid[0] && familyMembers['Single']) ||
      (invalid[1] && familyMembers['Spouse']) ||
      (invalid[2] && familyMembers['Parents']) ||
      (invalid[3] && familyMembers['Grandparents']) ||
      (invalid[4] && familyMembers['Siblings']) ||
      (invalid[5][0] && familyMembers.Children[0]['_0to18']) ||
      (invalid[5][1] && familyMembers.Children[1]['_18to21']) ||
      (invalid[5][2] && familyMembers.Children[2]['_22to24']) ||
      (invalid[5][3] && familyMembers.Children[3]['_25to26']) ||
      (invalid[5][4] && familyMembers.Children[4]['_27toUp'])
    ) {
      const ans = answer.slice(0, -1);
      setAnswer(ans + 'B');
      setErrorMsg('Sorry, some options are not supported');
    } else {
      const ans = answer.slice(0, -1);
      setAnswer(ans + 'A');
      setErrorMsg('');
    }
  };

  useEffect(() => {
    getFirstQuestion();
  }, []);

  useEffect(() => {
    if (questions?.length === 0) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }, [questions]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const msg =
      'Sorry there is no available country with this specifications at the moment';
    //console.log({ answer });
    let finalCountries = countries.filter((c) => c !== user.currentResidency);
    finalCountries = finalCountries.filter(
      (c) => c !== user.currentCitizenship
    );

    if (user.isEU) {
      finalCountries = finalCountries.filter((c) => c !== 'Greece');
      finalCountries = finalCountries.filter((c) => c !== 'Malta');
      finalCountries = finalCountries.filter((c) => c !== 'Spain');
      finalCountries = finalCountries.filter((c) => c !== 'Bulgaria');
    }

    if (user.isEEA) {
      finalCountries = finalCountries.filter((c) => c !== 'Ireland');
      finalCountries = finalCountries.filter((c) => c !== 'Malta');
    }

    if (finalCountries.length === 0) {
      finalCountries = [msg];
    }
    //getRecommendedCountries();
    setRecommendation(finalCountries);
    setIsEnd(true);

    if (finalCountries[0] !== msg) {
      console.log('Send email');
      const name = user.name;
      const formatedCountries = finalCountries.map((c) =>
        c.replace(/\s/g, '_')
      );
      const countriesForSpreadsheet = finalCountries.join(', ');

      addToSpreadsheet(countriesForSpreadsheet);

      sendRecommendation(formatedCountries, name)
        .then((res) => {
          console.log('Email sent');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Don't send email");
    }

    toggleContactInfo(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const optIndex = questions[0].options.findIndex(
      (opt) => opt.value === value
    );

    setAnswer(value);
    setCountries(questions[0].options[optIndex].countries);
  };

  const getPrevQuestions = () => {
    prevQuestion();
    setIsFetching(true);
    //console.log(`Current Step: ${currentStep - 1}`);

    switch (currentStep - 1) {
      case 1:
        getFirstQuestion();
        break;
      case 2:
        getSecondQuestion();
        break;
      case 3:
        getThirdQuestion();
        break;
      case 4:
        getFourthQuestion();
        break;
      case 5:
        getFifthQuestion();
        setIsFetching(false);
        break;
      default:
        break;
    }
  };

  const getNextQuestions = () => {
    nextQuestion();
    setIsFetching(true);
    //console.log(`Current Step: ${currentStep + 1}`);

    switch (currentStep + 1) {
      case 2:
        getSecondQuestion();
        break;
      case 3:
        getThirdQuestion();
        break;
      case 4:
        getFourthQuestion();
        break;
      case 5:
        getFifthQuestion();
        break;
      case 6:
        toggleContactInfo(true);
        setIsFetching(false);
        break;
      default:
        break;
    }
  };

  const cleanFamiliMember = () => {
    setFamilyMembers({
      Single: true,
      Spouse: false,
      Parents: false,
      Grandparents: false,
      Siblings: false,
      Children: [
        { _0to18: false },
        { _18to21: false },
        { _22to24: false },
        { _25to26: false },
        { _27toUp: false },
      ],
    });
  };

  useEffect(() => {
    //console.log(currentStep);
    if (currentStep === 4) {
      checkIsValid();
    }
    // eslint-disable-next-line
  }, [familyMembers]);

  useEffect(() => {
    if (
      countries[0] ===
      'Sorry there is no available country with this specifications at the moment'
    ) {
      setErrorMsg(
        'Sorry there is no available country with this specifications at the moment'
      );
    } else {
      setErrorMsg('');
    }
  }, [countries]);

  useEffect(() => {
    if (recommendation.length !== 0) {
      setTitle('Congratulations');
    } else if (isContactInfoSow) {
      setTitle('Contact Info');
    }
  }, [recommendation.length, isContactInfoSow]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      {/* {recommendation.length !== 0 ? (
        <h2>Congratulations</h2>
      ) : isContactInfoSow ? (
        <h2>Contact Info</h2>
      ) : (
        <h2>Questionnaire</h2>
      )} */}
      <h2>{title}</h2>

      {recommendation.length !== 0 && !isContactInfoSow && (
        <div className="result">
          <p>
            Congratulations on using our revolutionary consultancy app to find
            the best country or RCBI program for you and your family. It is
            fast, accurate, and free for you to use!
          </p>
          <p>
            The results of your enquiry will be sent to the email address you
            provided us with shortly.
          </p>
          <p>
            Please refer to the email for the next steps in your second passport
            process.
          </p>
        </div>
      )}

      {isContactInfoSow && (
        <ContactInfo saveContactInfo={() => setIsInfoCollected(true)} />
      )}

      {questions &&
        !isContactInfoSow &&
        recommendation.length === 0 &&
        questions.map((question, i) => (
          <div key={i} className="input-field">
            {question.type === 'checkbox' ? (
              <>
                <label htmlFor={question.htmlFor}>{question.question}</label>
                {question.options.map((option, i) =>
                  i < 5 ? (
                    <Checkbox
                      key={Math.random()}
                      title={option}
                      fnClick={(v) => {
                        setFamilyMembers({
                          ...familyMembers,
                          [option]: v,
                          // eslint-disable-next-line
                          //['Single']: false,
                        });
                        option === 'Single'
                          ? setFamilyMembers({
                              ...familyMembers,
                              Single: true,
                              Spouse: false,
                            })
                          : option === 'Spouse' &&
                            setFamilyMembers({
                              ...familyMembers,
                              Single: false,
                              Spouse: true,
                            });
                      }}
                      checked={familyMembers[option]}
                    />
                  ) : (
                    <div key={Math.random()}>
                      <h3>Children:</h3>
                      {option.map((children, i) => (
                        <Checkbox
                          key={Math.random()}
                          title={children.title}
                          fnClick={(v) => {
                            setFamilyMembers((prevFamilyMembers) => {
                              const childIndex = option.findIndex(
                                (element) => element.name === children.name
                              );
                              const newChildrens = [
                                ...prevFamilyMembers.Children,
                              ];
                              newChildrens[childIndex] = { [children.name]: v };
                              return {
                                ...prevFamilyMembers,
                                Children: newChildrens,
                                // eslint-disable-next-line
                                //['Single']: false,
                              };
                            });
                          }}
                          checked={
                            familyMembers.Children[
                              option.findIndex(
                                (element) => element.name === children.name
                              )
                            ][children.name]
                          }
                        />
                      ))}
                    </div>
                  )
                )}
              </>
            ) : (
              <>
                {question.status === 'invalid' ? (
                  <label htmlFor={question.msg}>{question.msg}</label>
                ) : (
                  <>
                    <label htmlFor={question.htmlFor}>
                      {question.question}
                    </label>
                    <select
                      name={question.htmlFor}
                      id={question.htmlFor}
                      onChange={handleChange}
                      value={answer}
                      required
                    >
                      {question.options.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </>
            )}
          </div>
        ))}

      <div className={`input-field btns ${isContactInfoSow && 'contact'}`}>
        {recommendation.length === 0 && !isContactInfoSow && (
          <Btn
            btnType="button"
            text="Previous"
            isDisabled={currentStep === 1 || isFetching}
            clicked={getPrevQuestions}
          />
        )}
        {howMany !== currentStep ? (
          <Btn
            btnType="button"
            text="Next"
            clicked={getNextQuestions}
            isDisabled={errorMsg || isFetching}
          />
        ) : (
          questions[0]?.status === 'valid' &&
          isContactInfoSow && (
            <Btn
              btnType="submit"
              text="Submit"
              isDisabled={
                isFetching ||
                !isInfoCollected ||
                countries[0] ===
                  'Sorry there is no available country with this specifications at the moment'
              }
            />
          )
          // (recommendation.length !== 0 ? null : (
          //   <Btn
          //     btnType="submit"
          //     text="Submit"
          //     isDisabled={
          //       isFetching ||
          //       countries[0] ===
          //         'Sorry there is no available country with this specifications at the moment'
          //     }
          //   />
          // ))
        )}
        {isEnd && !isContactInfoSow && (
          <Btn
            btnType="button"
            text="Finish"
            clicked={() => history.push('/')}
          />
        )}
      </div>
      {isFetching && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {errorMsg && (
        <p style={{ textAlign: 'center', fontSize: '.9rem', color: '#ccc' }}>
          {errorMsg}
        </p>
      )}
    </form>
  );
};

export default QuestionaryForm;
