const Checkbox = ({ fnClick, fnChange, title = '', checked = false }) => (
  <label style={{ display: 'inline-block', margin: '.5rem 1rem .5rem 0' }}>
    <input
      onClick={(e) => {
        if (fnClick !== undefined) fnClick(e.target.checked);
      }}
      onChange={(e) => {
        if (fnChange !== undefined) fnChange(e.target.checked);
      }}
      type="checkbox"
      checked={checked}
    />
    {' ' + title}
  </label>
);

export default Checkbox;
