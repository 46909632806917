import '../questionaryForm/QuestionaryForm.scss';
import { useState, useRef } from 'react';
import { Btn } from '../../../../components/UI';
import { useDispatch } from 'react-redux';
import { saveUser } from '../../../../store/actions/contactInfoActions';
import 'react-phone-number-input/style.css';
import ReCAPTCHA from 'react-google-recaptcha';

const RecaptchaForm = ({ startQuestionary }) => {
  const dispatch = useDispatch();
  const captcha = useRef(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [user, setUser] = useState({
    isEU: true,
    isEEA: true,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (captcha.current.getValue()) {
      console.log('The user is not a Bot');
      setErrorMsg(null);
      dispatch(
        saveUser({
          isEU: user.isEU,
          isEEA: user.isEEA,
        })
      );
      startQuestionary();
    } else {
      setErrorMsg('Dear Bot try again');
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'isEU' || name === 'isEEA') {
      const isTrue = value === 'true';
      setUser({ ...user, [name]: isTrue });
      return;
    }
  };

  const onChange = () => {
    if (captcha.current.getValue()) {
      console.log('El usuario no es un robot');
      setIsCaptchaValid(true);
      setErrorMsg(null);
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="input-field">
        <label htmlFor="isEU">
          Are you citizen of one of the EU (European Union) countries?
        </label>
        <select
          name="isEU"
          id="isEU"
          onChange={handleChange}
          value={user.isEU}
          required
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      </div>

      <div className="input-field">
        <label htmlFor="isEEA">
          Are you citizen of one of the EEA (European Economic Area) countries?
        </label>
        <select
          name="isEEA"
          id="isEEA"
          onChange={handleChange}
          value={user.isEEA}
          required
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      </div>

      <div className="input-field recaptcha">
        <ReCAPTCHA
          ref={captcha}
          sitekey="6Ld2EFIbAAAAAE_3f9-5yYCGmF4oPtDqnuD9KeD8"
          onChange={onChange}
        />
      </div>

      <div className="input-field btns">
        <Btn btnType="submit" text="Next" isDisabled={!isCaptchaValid} />
      </div>

      {errorMsg && <p className="error-msg">{errorMsg}</p>}
    </form>
  );
};

export default RecaptchaForm;
